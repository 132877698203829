var colors = {
  mainBack: "#ffffff",
  currentBack: "#ffffff",
  projectBack: "#182c3e",
  educationBack: "#182c3e",
  background: "",
};
var veriables = {
  loadingPage: false,
};
var pages = {
  home: 1,
  project: 2,
  education: 3,
  openedPage: 1,
  openTransition: true,
};

export { colors, veriables, pages };
