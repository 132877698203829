import * as THREE from "three";

import { gsap, Elastic, Back, Power1 } from "gsap";
import {
  OrbitControls,
  OrbitControls,
} from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { RoomEnvironment } from "three/examples/jsm/environments/RoomEnvironment";
import * as dat from "dat.gui";

import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import font from "three/examples/fonts/optimer_bold.typeface.json";

//custom imports
import { updateCursor, updateCursorHover, updateOnClick } from "./cursor";
import { initializePage, pages } from "./pages";
import { degToRad } from "three/src/math/MathUtils";
const fontLoader = new FontLoader();
const testfont = fontLoader.parse(font);
const roomObj = new URL("../bedroom.glb", import.meta.url);
const roomTexture = new THREE.TextureLoader().load("../texture.jpg");
const assetLoader = new GLTFLoader();
const group = new THREE.Group();
const video = document.getElementById("video");
var model,
  mouse,
  hoverEffect = true;
mouse = new THREE.Vector2();
const raycaster = new THREE.Raycaster();
const camera = new THREE.PerspectiveCamera(
  75,
  window.innerWidth / 2 / window.innerHeight,
  0.1,
  2000
);
const roomMaterial = new THREE.MeshBasicMaterial({ map: roomTexture });

assetLoader.load(roomObj.href, function (gltf) {
  model = gltf.scene;
  model.traverse(function (node) {
    if (node.isMesh) {
      node.castShadow = true;
      node.receiveShadow = true;
    }
  });

  // model.scale.set(1, 2);
  group.add(gltf.scene);
  video.play();
  initializePage();
});
const renderer = new THREE.WebGL1Renderer({ antialias: true, alpha: true });
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFShadowMap;
renderer.setSize(window.innerWidth / 2, window.innerHeight);
renderer.setPixelRatio(window.devicePixelRatio);
document.body.appendChild(renderer.domElement);
renderer.outputEncoding = THREE.sRGBEncoding;

//dat setting
// const datGUI = new dat.GUI();
const options = {
  x: -3,
  y: 2,
  z: -1,
  height: 1,
  width: 1.5,
};

// datGUI.add(options, "x", -5, 5);
// datGUI.add(options, "y", -5, 5);
// datGUI.add(options, "z", -5, 5);
// datGUI.add(options, "height", 0, 2);
// datGUI.add(options, "width", 0, 2);

const scene = new THREE.Scene();
scene.background = new THREE.Color(options.back);
scene.fog = new THREE.FogExp2(0xffffff, 0.025);

//ambient light
const ambientLight = new THREE.AmbientLight(0xffffff, 0.2);
scene.add(ambientLight);

//spot light
const directionalLight = new THREE.DirectionalLight(0xcaf7f4, 0.8);
const directionalLightHelper = new THREE.DirectionalLightHelper(
  directionalLight
);
directionalLight.castShadow = true;
directionalLight.shadow.camera.far = 20;
directionalLight.shadow.normalBias = 0.268;
directionalLight.shadow.radius = 1;
directionalLight.position.set(0, 10, -1);
directionalLight.intensity = 0.5;
scene.add(directionalLight);

const g = new TextGeometry("< Projects", {
  font: testfont,
  height: 0,
  size: 0.2,
});
const materials = [
  new THREE.MeshPhongMaterial({ color: 0xff6600 }), // front
  new THREE.MeshPhongMaterial({ color: 0x0000ff }), // side
];
const textMesh1 = new THREE.Mesh(g, materials);
textMesh1.castShadow = true;
textMesh1.position.set(-3.99, -0.5, -2.5);
textMesh1.material.opacity = 0;
textMesh1.rotation.set(0, 1.5708, 0);
group.add(textMesh1);

const spotlight = new THREE.SpotLight(0xfff387, 0.6);
const spotlightHelper = new THREE.SpotLightHelper(spotlight);
spotlight.castShadow = true;
spotlight.shadow.camera.far = 20;
spotlight.shadow.mapSize.set(1020, 1020);
spotlight.shadow.normalBias = 0.05;
spotlight.shadow.radius = 1;
spotlight.position.set(
  -0.3485154715024207,
  2.6606216151664928,
  4.033901463623856
);
spotlight.rotation.set(
  0.6091804050434543,
  -0.36905155452938915,
  -0.2465609825633704
);
spotlight.angle = 1;
spotlight.penumbra = 0;
spotlight.intensity = 0.5;
spotlight.shadow.bias = -0.001;
scene.add(spotlight);
camera.position.set(15, 15, -15);
camera.rotation.set(-2.356194490192345, 0.6154797086703874, 2.617993877991494);
// const orbit = new OrbitControls(camera, renderer.domElement);
const axisHelper = new THREE.AxesHelper(5);

//plane
const material = new THREE.MeshBasicMaterial({
  color: 0xf06262,
  side: THREE.DoubleSide,
});

//video
//initialize video
let videoTexture = new THREE.VideoTexture(video);
videoTexture.magFilter = THREE.LinearFilter;
videoTexture.minFilter = THREE.LinearFilter;

let videoMaterial = new THREE.MeshBasicMaterial({
  map: videoTexture,
  side: THREE.FrontSide,
  toneMapped: false,
});
let videoGeometry = new THREE.PlaneGeometry(1.2, 0.7);
let videoScreen = new THREE.Mesh(videoGeometry, videoMaterial);
videoScreen.position.set(-3.6, 2.02, -1.065);
videoScreen.rotateY(degToRad(90));

group.add(videoScreen);
//make video object

scene.add(group);
function animate() {
  // orbit.update();
  scene.background = null;
  renderer.render(scene, camera);
}

renderer.setAnimationLoop(animate);

function updateModel(e) {
  if (hoverEffect == true) {
    group.rotation.set(
      -0 + (window.innerWidth / 3 - e.clientY) / (window.innerWidth * 2),
      -0.1 - (window.innerWidth / 3 - e.clientX) / (window.innerWidth * 2),
      -0 + (window.innerWidth / 3 - e.clientY) / (window.innerWidth * 2)
    );
  }
}

function updateMouse(e) {
  mouse.x =
    ((e.clientX - renderer.domElement.offsetLeft) /
      renderer.domElement.clientWidth) *
      2 -
    1;
  mouse.y =
    -(
      (e.clientY - renderer.domElement.offsetTop) /
      renderer.domElement.clientHeight
    ) *
      2 +
    1;
}

document.addEventListener("mousemove", function (e) {
  checkHover();
  updateModel(e);
  updateMouse(e);
  updateCursor(e);
});

//check interection
document.addEventListener("click", (e) => {
  checkInterection(e);
  console.log(camera.rotation);
});
function focusMain() {
  gsap.to(camera.position, {
    x: 6.325110101156437,
    y: 6.966003116713916,
    z: -7.708886782129645,
    duration: 3,
    delay: 0,
    ease: Elastic.easeInOut,
  });
  gsap.to(camera.rotation, {
    x: -2.356194490192345,
    y: 0.6154797086703874,
    z: 2.617993877991494,
    duration: 3,
    delay: 0,
    ease: Elastic.easeInOut.config(1, 3),
    onComplete: () => {
      hoverEffect = true;
      activeClick = "home";
    },
  });
}
function focusProject() {
  hoverEffect = false;
  // moveCamera(-2.7, 2.9, -1.3, 3.0, 1.5, -3.0);
  gsap.to(group.rotation, {
    x: 0,
    y: 0,
    z: 0,
    duration: 1,
  });
  gsap.to(group.position, {
    x: 0,
    y: 0,
    z: 0,
    duration: 1,
  });
  gsap.to(camera.position, {
    x: -1.0912174938901869,
    y: 2.6576913656422634,
    z: -1.9925526422854145,
    duration: 1.5,
  });

  gsap.to(camera.rotation, {
    x: -1.6800270710804033,
    y: 1.5244806104519255,
    z: 1.6801434018160195,
    duration: 1.5,
  });
}
function focusEducation() {
  hoverEffect = false;
  // moveCamera(-2.7, 2.9, -1.3, 3.0, 1.5, -3.0);
  gsap.to(group.rotation, {
    x: 0,
    y: 0,
    z: 0,
    duration: 1,
  });
  gsap.to(group.position, {
    x: 0,
    y: 0,
    z: 0,
    duration: 1,
  });
  gsap.to(camera.position, {
    x: 2.3964322808341354,
    y: 2.227126115603933,
    z: 2.001537165614489,
    duration: 1.5,
  });
  gsap.to(camera.rotation, {
    x: -3.103330952048338,
    y: 0.005427499038900412,
    z: 3.1415550461700366,
    duration: 1.5,
  });
}

var activeClick;
function checkInterection(event) {
  raycaster.setFromCamera(mouse, camera);

  const intersects = raycaster.intersectObjects(model.children);
  intersects.forEach((e) => {
    if (e.object.name == "Cube050_3" && e.object.name != activeClick) {
      activeClick = e.object.name;
      updateOnClick(event, e.object.name);
    }
    if (e.object.name == "Cube016" && e.object.name != activeClick) {
      updateOnClick(event, e.object.name);
      activeClick = e.object.name;
    }
  });
}

const educationTT = document.getElementById("educationTT");
const projectTT = document.getElementById("projectTT");
function checkHover() {
  raycaster.setFromCamera(mouse, camera);

  // var _project = false;
  // var _education = false;
  var _element = false;
  const intersects = raycaster.intersectObjects(model.children);
  intersects.forEach((e) => {
    if (e.object.name == "Cube050_3") {
      _element = true;
      projectTT.style.display = "flex";
      educationTT.style.display = "none";
    }
    if (e.object.name == "Cube016") {
      _element = true;
      educationTT.style.display = "flex";
      projectTT.style.display = "none";
    }
  });

  _element ? updateCursorHover(true) : updateCursorHover(false);
}

export { focusProject, focusMain, focusEducation };
