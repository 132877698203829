import { gsap, Elastic, Power4, TweenMax } from "gsap";
import { colors } from "./globalVeriable";
import { openTransition, pages, showProject, showEducation } from "./pages";
var hoverOn = false;
const cursor = document.getElementById("cursor");
const tooltip = document.getElementById("hoverToolTip");
const main = document.getElementById("_transition");

// jQuery("#cursor").connections({ to: "#hoverToolTip" });
function updateCursor(e) {
  TweenMax.to($("#cursor"), 0.4, {
    x: e.pageX - cursor.clientWidth / 2,
    y: e.pageY - cursor.clientHeight / 2,
    onUpdate: () => {
      if (hoverOn) {
        tooltip.style.left = e.clientX - tooltip.clientWidth / 2 + 200 + "px";
        tooltip.style.top = e.clientY - tooltip.clientHeight / 2 - 90 + "px";
        jQuery("#cursor").connections("update");
      }
    },
  });
  // if (hoverOn) {
  //   tooltip.style.left = e.clientX - tooltip.clientWidth / 2 + 100 + "px";
  //   tooltip.style.top = e.clientY - tooltip.clientHeight / 2 - 60 + "px";
  // }
  jQuery("#cursor").connections("update");
}

function updateCursorHover(bool) {
  //updatetooltip

  if (bool == true && hoverOn == false) {
    gsap.to(".cursor", {
      //   background: "rgba(75, 177, 87, 0.4)",
      height: 50,
      width: 50,
      duration: 0.5,
      // ease: Elastic.easeOut.config(1, 0.2),
    });
    gsap.to(".hoverToolTip", {
      height: 50,
      opacity: 1,
      duration: 0.4,
      // ease: Elastic.easeOut.config(1, 0.2),
    });
    jQuery("#cursor").connections({ to: "#hoverToolTip" });

    hoverOn = true;
  }

  if (bool == false && hoverOn == true) {
    jQuery("#cursor").connections("remove");
    gsap.to(".cursor", {
      height: 30,
      width: 30,
      duration: 0.5,
      ease: Elastic.easeOut.config(1, 0.2),
    });
    gsap.to(".hoverToolTip", {
      height: 0,
      opacity: 0,
      duration: 0.4,
    });

    hoverOn = false;
  }
}
function updateOnClick(e, element) {
  if (element == "Cube050_3" && pages.openedPage != pages.project) {
    main.style.left = e.clientX - 25 + "px";
    main.style.top = e.clientY - 25 + "px";
    showProject();
  }
  if (element == "Cube016" && pages.openedPage != pages.education) {
    main.style.left = e.clientX - 25 + "px";
    main.style.top = e.clientY - 25 + "px";
    showEducation();
  }
}

export { updateCursor, updateOnClick, updateCursorHover };
