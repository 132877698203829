import { gsap, Elastic, Power0, Power1, Power2, Power3, Power4 } from "gsap";
import { colors, veriables, pages } from "./globalVeriable";
import { focusEducation, focusMain, focusProject } from "./main";
const main = document.getElementById("_transition");
function initializePage() {
  const pathName = location.pathname;

  if (pathName == "/" || pathName == "/home") showHome();
  if (pathName == "/projects") showProject();
  if (pathName == "/education") showEducation();
  if (pathName.match(/projects\/[0-9]/g)) {
    showOneProject();
    console.log("changed");
  }
}

window.onpopstate = () => {
  initializePage();
};
window.addEventListener("locationchange", () => {
  console.log("triggered" + location.pathname);
  initializePage();
});

function openTransition() {
  return new Promise((resolve, reject) => {
    gsap.to("._main", {
      width: 50,
      height: 50,
      background: colors.projectBack,
      duration: 0.5,
      borderRadius: 50,
      ease: Elastic.easeOut.config(1, 0.2),
      onComplete: () => {
        gsap.to("._main", {
          left: window.innerWidth / 2 - 100,
          top: 0,
          height: window.innerHeight,
          width: window.innerWidth / 2 + 100,
          borderRadius: 0,
          duration: 1,
          ease: Power4.easeOut,
          onComplete: () => {
            colors.currentBack = colors.projectBack;
            setTimeout(() => {
              main.style.visibility = "hidden";
            }, 10);
            pages.openTransition = true;
            resolve("success");
          },
        });
      },
    });
  });
}
function closeTransition() {
  return new Promise((resolve, reject) => {
    gsap.to(".main", {
      visibility: "visible",
      duration: 0.1,

      onStart: () => {
        main.style.visibility = "visible";
        colors.currentBack = colors.mainBack;
      },
      onComplete: () => {
        gsap.to("._main", {
          left: 0,
          top: 0,
          height: "0px",
          width: "0px",
          borderRadius: 50,
          duration: 1,

          delay: 1,
          ease: Power4.easeOut,
          onComplete: () => {
            resolve("success");
            pages.openTransition = false;
          },
        });
      },
    });
  });
}
function showHome() {
  if (pages.openTransition) {
    veriables.loadingPage = true;
    closeTransition().then(() => {
      veriables.loadingPage = false;
    });
    pages.openedPage = pages.home;
    focusMain();
  } else {
    pages.openedPage = pages.home;
    focusMain();
  }
}
function hideHome() {
  // gsap.to(".intro", {
  //   duration: 1,
  //   top: -innerHeight / 3,
  //   opacity: 0,
  // });
}
function showOneProject() {
  pages.openedPage = pages.project;
  veriables.loadingPage = false;
  colors.currentBack = colors.mainBack;
  focusProject();
}
function showProject() {
  // hideHome();
  return new Promise((resolve, reject) => {
    veriables.loadingPage = true;
    if (!pages.openTransition) {
      openTransition().then(() => {
        pages.openedPage = pages.project;
        veriables.loadingPage = false;
        window.history.pushState({}, "Projects", "/projects");
        resolve("success");
      });
    } else {
      pages.openedPage = pages.project;
      veriables.loadingPage = false;
      colors.currentBack = colors.projectBack;
      pages.openTransition = true;
    }
    focusProject();
  });
}
function showEducation() {
  // hideHome();
  return new Promise((resolve, reject) => {
    veriables.loadingPage = true;
    if (!pages.openTransition) {
      openTransition().then(() => {
        pages.openedPage = pages.education;
        veriables.loadingPage = false;

        window.history.pushState({}, "Education", "/education");
        resolve("success");
      });
    } else {
      pages.openedPage = pages.education;
      veriables.loadingPage = false;
      colors.currentBack = colors.educationBack;
      pages.openTransition = true;
    }
    focusEducation();
  });
}

export {
  initializePage,
  showHome,
  showProject,
  showEducation,
  openTransition,
  pages,
};
